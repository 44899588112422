#login_wrapper .login_content { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 80vh;
    background-color: white;
    z-index: 1000;
    border: 1px solid var(--grey-30);
    box-shadow: 0 0 25px rgb(0 0 0 / 10%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
}
#login_wrapper .login_bg { 
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--cn);
    opacity: 0.2;
    z-index: 999;
}

#login_wrapper .login_content .close_icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--grey-100);
    padding: 5px;
    cursor: pointer;
    transition: 0.2s;
}
#login_wrapper .login_content .close_icon:hover {
    padding: 3px;
}
#login_wrapper .login_content .close_icon.zoom {
    z-index: 99;
    color: white;
}
#login_wrapper .login_content figure {
    height: 100px;
    width: auto;
    position: relative;
    display: flex;
    justify-content: center;
    margin: 25px 0;
}
#login_wrapper .login_content figure img {
    max-height: 100%;
    max-width: 100%;
}
#login_wrapper .login_content h2 {
    display: flex;
    text-transform: uppercase;
    width: 80%;
    color: var(--grey-70);
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}
#login_wrapper .login_content .innerLogin { 
    margin: 15px 0;
    display: flex;
    justify-content: center;
    /* height: calc(100% - 267px); */
}
#login_wrapper .login_content .innerLogin .login { 
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#login_wrapper .login_content .cread {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}
#login_wrapper .login_content .cread p, #login_wrapper .login_content .cread ul { 
    padding: 7px 25px;
    font-size: 13px;
}
#login_wrapper .login_content .cread ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}
#login_wrapper .login_content .cread figure {
    margin: 0;
    text-align: center;
    height: max-content;
    transition: .2s;
    width: 33%;
}
#login_wrapper .login_content .cread figure img {
    border: 1px solid var(--grey-20);
    cursor: pointer;
}
#login_wrapper .login_content .cread figure.zoom {
    position: unset;
}
#login_wrapper .login_content .cread figure.zoom img {
    position: absolute;
    border: 0;
    width: 100%;
    max-width: unset;
    max-height: unset;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    transition: .2s;
    z-index: 1;
}
#login_wrapper .login_content .cread figure.zoom::after {
    content: '';
    width: 100%;
    position: absolute;
    display: block;
    height: 100%;
    background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    z-index: 0;
}
#login_wrapper .login_content .cread figure .icon {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: .2s;
}
#login_wrapper .login_content .cread figure:hover .icon{
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);

}
#login_wrapper .login_content .cread figure:hover img {
    opacity: .8;
}
#login_wrapper .login_content .forgot_password {
    color: var(--cn);
    font-size: 13px;
    max-width: 300px;
    text-align: right;
    width: 100%;
    cursor: pointer;
}
#login_wrapper .login_content .error_message {
    color: var(--error);
    font-size: 13px;
    margin-top: 5px;
    min-height: 18px;
}
#login_wrapper .login_content .login_button {
    width: 100%;
    background-color: var(--grey-100);
    color: white;
    border-radius: 5px;
    max-width: 300px;
    margin: 10px 0 5px 0;
    padding: 10px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 900;
    cursor: pointer;
}

#login_wrapper .already_account {
    color: var(--cn);
    font-size: 13px;
    max-width: 300px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

#login_wrapper  .login_type_buttons {
    display: flex;
}
#login_wrapper .type_button {
    border: 1px solid var(--cn);
    background-color: white;
    color: var(--cn);
    border-radius: 5px;
    padding: 7px 5px;
    margin: 0 5px;
    font-size: 13px;
    transition: .5s;
    cursor: pointer;
}
#login_wrapper .type_button.active, .type_button:hover {
    border: 1px solid var(--cn);
    background-color: var(--cn);
    color: var(--white);
}

#login_wrapper .inputFieldWrapper { width: 100%; margin: 10px 0;}
#login_wrapper .form__label::after { height: 3px !important;}

#login_wrapper .login_extra_info {
    margin: 50px 0;
}

#login_wrapper .login_extra_info_fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    position: relative;
}

#login_wrapper .inputFieldWrapper.half, .selectField, .inputField.calendarDate{ 
    width: 40%;
    margin: 10px;
} 
#login_wrapper .inputFieldWrapper.half  .form__group {
    margin: 0;
}
.pref_language {
    position: absolute;
    left: 70px;
    top: 2px;
    z-index: 1;
    font-size: 11px;
    background: white;
}
.login_extra_info_fields .login_button { max-width: 83% !important;}

@media only screen and (max-width: 550px) {
    .cread-container {
        flex-direction: column;
        align-items: center;
        
    }
    .cread-container ul {
        order: -1;
    }
    #login_wrapper .login_content {
        overflow-y: auto;
    }
}